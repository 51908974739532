<template>
	<div class="organisation">
        <div class="container-fluid text-center">
            <div class="text-center title my-5">
                <img src="@/assets/our-organisation/our-organisation.png" alt="">
                <h1 class="">Our Organisation</h1>
            </div>
            <div class="col-12 px-3 mb-4">
                <h5>Vision</h5>
                <p>A Vibrant Care Community Enabling our People to Live Well and Age Gracefully</p>
            </div>
            <div class="col-12 px-3">
                <h5>Mission</h5>
                <p>Empowering Seniors and Clients <br>Transforming the Care Community</p>
            </div>
            <div class="col-12 secondary-image-container mt-5">
                <h5>Organisation Structure</h5>
                <img class="my-5 img-fluid" src="@/assets/our-organisation/AICOrgChart.png" alt="orgchart">
            </div>
        </div>
        <PageNavigation prev='/one-aic-family' next='/our-leadership' />
	</div>	
</template>

<script>
import PageNavigation from '@/components/PageNavigation.vue'

export default {
    name: 'OurOrganisation',

    components: {
        PageNavigation
    },
}
</script>

<style scoped>
.organisation {
    margin-top: 100px;
    min-height: 100vh;
}
h5 {
    font-size: 21px;
    font-family: 'Montserrat SemiBold';
    color: #363636;
}
p {
    font-size: 18px;
    font-family: 'Frutiger Neue LT Book';
}
/*mobile */
@media(max-width: 768px) {
    h1 {
        font-size: 24px;
    }
    h5 {
        font-size: 18px;
    }
    p {
        font-size: 16px;
    }
}
</style>

<style>
h1 {
    color: #DA3406;
    font-size: 38px;
    font-family: 'Montserrat SemiBold';
}

/*mobile */
@media(max-width: 768px) {
    h1 {
        font-size: 24px;
    }
}
</style>